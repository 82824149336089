var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { staticStyle: { "min-width": "500px", "overflow-x": "auto" } },
    [_c("p", [_vm._v("我是左侧页面")]), _c("img-turn-page")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }