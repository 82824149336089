var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      staticStyle: { "min-width": "800px", "overflow-x": "auto" },
      attrs: { title: "树形结构图片翻页查看" },
    },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c("a-tree", {
                style: {
                  height: "500px",
                  "border-right": "2px solid #c1c1c1",
                  "overflow-y": "auto",
                },
                attrs: {
                  showLine: "",
                  treeData: _vm.treeData,
                  expandedKeys: [_vm.expandedKeys[0]],
                  selectedKeys: _vm.selectedKeys,
                },
                on: { expand: _vm.onExpand, select: this.onSelect },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 19 } },
            [
              _c(
                "a-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "a-col",
                    {
                      staticStyle: {
                        "padding-left": "2%",
                        "margin-bottom": "10px",
                      },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary" }, on: { click: _vm.prev } },
                        [
                          _c("a-icon", { attrs: { type: "left" } }),
                          _vm._v("上一页"),
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "8px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.next },
                        },
                        [
                          _vm._v("下一页"),
                          _c("a-icon", { attrs: { type: "right" } }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-left": "15%",
                            "font-weight": "bolder",
                          },
                        },
                        [_vm._v(_vm._s(_vm.navName))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticStyle: { "padding-left": "2%" },
                      attrs: { span: 24 },
                    },
                    [_c("img", { attrs: { src: _vm.imgUrl, preview: "" } })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }